<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">

    <form ref="form" @submit.stop.prevent="handleSubmit(submit)">
      <b-modal v-model="dialog" id="modal-create-student" centered :title="title + ' студента'"
        header-bg-variant="primary" header-text-variant="light" cancel-title="Закрити" ok-title="Зберегти" size="xl"
        @ok.prevent="handleSubmit(submit)" @hidden="$emit('reset')">
        <validation-provider name="Студент" rules="required" v-slot="validationContext">
          <b-form-group id="student-group" label="Студент" label-for="student-input">
            <CSelect :loading="loadingStudents" :options="students" id="student-input" label="full_name_groups"
              v-model="item.full_name" :invalid="validationContext.invalid && validationContext.validated"
              :valid="validationContext.valid && validationContext.validated" @search="getSearchStudent"
              @input="(val) => selectStudent = val" :reduce="(option) => option" :disabled="!!item.id" />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-row>
          <b-col col lg="5">
            <validation-provider name="Ступень" rules="required" v-slot="validationContext">
              <b-form-group id="degree-group" label="Ступень" label-for="degree-input">
                <CSelect :options="fields.degrees" id="degree-input" label="degree" v-model="item.degree_id"
                  :invalid="validationContext.invalid && validationContext.validated"
                  :valid="validationContext.valid && validationContext.validated"
                  :reduce="(option) => option.degree_id" />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>
          <b-col col lg="3">
            <validation-provider name="Група" rules="required" v-slot="validationContext">
              <b-form-group id="group" label="Група" label-for="input-group">
                <b-form-input id="input-group" v-model="item.group" type="text" placeholder="ЕП-41"
                  :state="GlobalGetValidationState(validationContext)"></b-form-input>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col col lg="3">
            <validation-provider name="Курс" rules="required|min_value:1|max_value:10" v-slot="validationContext">
              <b-form-group id="course" label="Курс" label-for="input-course">
                <b-form-input id="input-course" v-model="item.course" type="number" placeholder="1"
                  :state="GlobalGetValidationState(validationContext)" max="10" min="1"></b-form-input>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col col lg="1">
            <validation-provider name="Школяр" v-slot="validationContext">
              <b-form-group id="is_school" label="Школяр" label-for="input-is_school" class="text-lg-center">
                <b-form-checkbox size="lg" id="input-is_school" v-model="item.is_school" name="is_school">
                </b-form-checkbox>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <validation-provider name="Факультет/Інститут" rules="required" v-slot="validationContext">
          <b-form-group id="unit-group" label="Факультет/Інститут" label-for="unit-input">
            <CSelect :options="fields.units" id="unit-input" label="department" v-model="item.unit_id"
              :invalid="validationContext.invalid && validationContext.validated"
              :valid="validationContext.valid && validationContext.validated" :reduce="(option) => option.id" />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider name="Кафедра" rules="required" v-slot="validationContext">
          <b-form-group id="student-group" label="Кафедра" label-for="student-input">
            <CSelect :options="departments" id="student-input" label="department" v-model="item.department_id"
              :invalid="validationContext.invalid && validationContext.validated"
              :valid="validationContext.valid && validationContext.validated" :reduce="(option) => option.id" />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>


      </b-modal>
    </form>
  </validation-observer>
</template>

<script>
import CSelect from "@/components/Forms/CSelect";
import api from "@/api";
import debounce from 'lodash.debounce';
import { API, ALLOWED_REQUEST_PARAMETERS } from "@/api/constants-api";

export default {
  name: "mCreateStudent",
  components: { CSelect },
  data() {
    return {
      dialog: this.open,
      defaultItem: {
        asu_id: null,
        degree_id: null,
        group: null,
        course: null,
        is_school: null,
        unit_id: null,
        department_id: null,
      },
      item: {},
      students: [],
      search: '',
      loadingStudents: false,
      selectStudent: null,
      departments: [],
    }
  },
  props: {
    open: {
      default: false
    },
    title: '',
    student: null,
    fields: {
      required: true,
    }
  },
  watch: {
    open(v) {
      this.dialog = v
    },
    student(v) {
      if (v && v !== null) {
        this.item = v;
        this.apiGetStudents({ full_name: this.item.full_name });
      }
    },
    'item.unit_id'(v, old) {
      if (old && v !== old) {
        this.item.department_id = null;
      }
      if (v !== null) {
        this.apiGetDepartments(v);
      } else {
        this.departments = [];
      }
    }
  },
  mounted() {
    this.apiGetStudents();
  },
  methods: {
    apiGetDepartments(id) {
      api.get(API.GET_DEPARTMENTS_LIST + '/' + id).then(({ data }) => {
        this.departments = data
      }).catch(er => {
        console.log(er)
        this.ToastError(er.response.data.message);
      })
    },
    apiGetStudents(payload) {
      const options = this.GlobalHandlingRequestParameters(ALLOWED_REQUEST_PARAMETERS.GET_ASU_SEARCH_PERSON, payload);

      api.get(API.ASU_ALL_STUDENTS_SEARCH, options).then(({ data }) => {
        this.loadingStudents = false;
        this.students = data;
      }).catch((errors) => {
        this.loadingStudents = false;
        console.log(errors)
      });
    },
    submit() {
      const degree = this.fields.degrees.find(el => el.degree_id === this.item.degree_id);
      const department = this.departments.find(el => el.id === this.item.department_id)?.department;
      const unit = this.fields.units.find(el => el.id === this.item.unit_id)?.department;

      const data = { ...degree, ...this.item, ...this.selectStudent, department, unit }

      this.$emit('submit', data);
    },
    resetForm() {
      this.item = { ...this.defaultItem }; // Скидаємо дані форми до початкових
      this.selectStudent = null; // Очищуємо вибраного керівника
      this.students = []; // Очищуємо список керівників
      this.departments = []; // Очищуємо список кафедр
    },
    getSearchStudent: debounce(async function (searchStr) {
      if (searchStr.length > 3) {
        this.loadingStudents = true;
        await this.apiGetStudents({ full_name: searchStr });
      }
    }, process.env.VUE_APP_DEBOUNCE_DELAY),
  }
}
</script>

<style scoped></style>
