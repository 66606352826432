<template>
  <div>
    <b-container>
      <b-row class="justify-content-md-between">
        <b-col col lg="7">
          <b-button variant="light">
            Всього наукових керівників
            <b-badge variant="warning">{{ meta.total }}</b-badge>
          </b-button>
        </b-col>
        <b-col col lg="5" class="text-right">
          <b-button-group>
            <b-button variant="outline-primary" @click="showFilter = !showFilter">
              Фільтри
              <b-icon :icon="!showFilter ? 'caret-down' : 'caret-up'" font-scale="1" />
            </b-button>
            <b-button variant="outline-primary" @click="create">
              Створити наукового керівника
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <b-row class="mt-3" v-show="showFilter">
        <FiltersBySupervisors ref="filters" :filters="filters"
          @init="(filters) => apiGetItems(this.current_page, filters)" />
      </b-row>
    </b-container>

    <b-container fluid class="mt-5">
      <b-table hover :items="supervisors" :fields="fields" ref="tableSupervisors" head-variant="primary" show-empty
        stacked="lg">
        <template #table-colgroup="scope">
          <col v-for="field in scope.fields" :key="field.key"
            :style="{ width: field.key === 'is_school' ? '100px' : '' }">
        </template>

        <template #cell(id)="row">
          {{ meta.from + row.index }}
        </template>

        <template #cell(unit)="row">
          <p class="mb-0">{{ row.item.unit }}</p>
          <p class="text-muted">{{ row.item.department }}</p>
        </template>

        <template #cell(actions)="row">
          <b-button v-if="row.item.actions.edit" v-b-tooltip class="mt-1 mr-1" variant="outline-primary" size="sm"
            title="Редагувати" @click="edit(row.item)">
            <b-icon icon="pencil" font-scale="1" />
          </b-button>
          <b-button v-if="row.item.actions.delete" v-b-tooltip class="mt-1 mr-1" variant="outline-danger" size="sm"
            title="Видалити" v-b-modal.modal-destroy @click="() => { item = row.item; dialogDestroy = false }">
            <b-icon icon="trash" font-scale="1" />
          </b-button>
        </template>

        <template #empty="scope">
          <h6 class="text-center my-2">Немає записів для показу</h6>
        </template>

        <template #table-caption v-if="meta && meta.total !== 0">
          <div class="text-right">
            Показано від {{ meta.from }} до {{ meta.to }} із {{ meta.total }} записів
          </div>
        </template>

      </b-table>
      <b-pagination v-if="meta" v-model="current_page" class="justify-content-center" :total-rows="meta.total"
        :per-page="meta.per_page"></b-pagination>
    </b-container>

    <m-create-supervisor :open="showModalCreate" :fields="fieldsCreate" :title="item ? 'Редагувати' : 'Створити'"
      @submit="store" :student="item" ref="mCreateSupervisor" @reset="resetCreate" />


    <m-destroy v-if="this.item" :item="this.item" :open="dialogDestroy"
      :text="'Хочите видалити керівника ' + this.item.full_name + '?'" @destroy="destroy"
      @reset="dialogDestroy = false" />


  </div>
</template>

<script>
import api from "@/api";
import { API, ALLOWED_REQUEST_PARAMETERS } from "@/api/constants-api";
import FiltersBySupervisors from "@/views/pages/progress/filters/BySupervisors";
import mDestroy from "@/components/modal/destroy";
import Toasts from "@/mixins/Toasts";
import MCreateSupervisor from "@/components/modal/Progress/createSupervisor";

export default {
  name: "ProgressSupervisorsPage",
  components: { MCreateSupervisor, mDestroy, FiltersBySupervisors },
  mixins: [Toasts],
  data() {
    return {
      supervisors: [],
      meta: [],
      current_page: 1,
      filters: [],
      fields: [
        { key: 'id', label: 'Id' },
        { key: 'full_name', label: 'ПІБ наукового керівника' },
        { key: 'position', label: 'Посада' },
        { key: 'unit', label: 'Факультет/Інститут/Кафедра' },
        { key: 'actions', label: 'Дії' },
      ],
      showFilter: false,
      item: null,
      dialogDestroy: false,
      fieldsCreate: [],
      showModalCreate: false,
    }
  },
  watch: {
    current_page: function (currentPage) {
      this.apiGetItems(currentPage, this.$refs.filters.getFilterData());
    },
  },
  mounted() {
    this.apiGetItems();
    this.apiGetFilters();
  },
  methods: {
    apiGetFilters() {
      api.get(API.PROGRESS_SUPERVISORS_FILTERS).then(({ data }) => {
        this.filters = data
      }).catch((errors) => {
        console.log('PROGRESS_SUPERVISORS_FILTERS', errors)
      })
    },

    apiGetItems(page = 1, payload) {
      const options = this.GlobalHandlingRequestParameters(ALLOWED_REQUEST_PARAMETERS.GET_PROGRESS_STUDENT, { page, ...payload });

      api.get(API.PROGRESS_SUPERVISORS, options, { showLoader: true }).then(({ data }) => {

        this.supervisors = data.data;
        this.meta = data.meta;

      }).catch((errors) => {
        console.log('PROGRESS_SUPERVISORS', errors)
      })
    },

    apiGetCreate() {
      api.get(API.PROGRESS_SUPERVISORS_CREATE, null, { showLoader: true }).then(({ data }) => {
        this.fieldsCreate = data;
      }).catch((errors) => {
        console.log('PROGRESS_SUPERVISORS_CREATE', errors)
      })
    },

    destroy(item) {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-destroy')
      })

      api.destroy(API.PROGRESS_SUPERVISORS, item.id).then(({ data }) => {
        this.ToastSuccess(data.message);
        this.apiGetItems(this.current_page, this.$refs.filters.getFilterData());
      }).catch((er) => {
        console.log('er PROGRESS_SUPERVISORS', er)
        this.ToastError(er.response.data.message)
      })
    },
    store(item) {
      if (item.id) {
        console.log('edit', item);
        api.patch(API.PROGRESS_SUPERVISORS, item.id, item).then(({ data }) => {
          this.ToastSuccess(data.message);
          this.showModalCreate = false;
          this.resetCreate();
          this.apiGetItems(this.current_page, this.$refs.filters.getFilterData());
          this.apiGetFilters();
        }).catch((er) => {
          console.log('er PROGRESS_SUPERVISORS', er)
          this.ToastError(er.response.data.message)
        })
      } else {
        api.post(API.PROGRESS_SUPERVISORS, item).then(({ data }) => {
          this.ToastSuccess(data.message);
          this.showModalCreate = false;
          this.resetCreate();
          this.apiGetItems(this.current_page, this.$refs.filters.getFilterData());
          this.apiGetFilters();
        }).catch((er) => {
          console.log('er PROGRESS_SUPERVISORS', er)

          this.ToastError(er.response.data.message)
        })
      }
    },
    edit(item) {
      this.apiGetCreate();
      this.item = item;
      this.showModalCreate = true;
    },
    async create() {
      this.resetCreate();
      this.apiGetCreate();
      this.showModalCreate = true;
    },
    resetCreate() {
      this.$refs.mCreateSupervisor.item = {};
      this.showModalCreate = false;
      this.item = null;
    },
  }
}
</script>

<style scoped></style>
