import { render, staticRenderFns } from "./F5.vue?vue&type=template&id=426dd680&scoped=true"
import script from "./F5.vue?vue&type=script&lang=js"
export * from "./F5.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "426dd680",
  null
  
)

export default component.exports