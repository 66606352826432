<template>
  <div>
    <b-container>
      <b-row class="flex-wrap justify-content-md-between">
        <b-col md="12" lg="8"></b-col>
        <b-col md="12" lg="4" class="text-right">
          <b-button-group>
            <b-button variant="outline-primary" @click="showFilter = !showFilter">
              Фільтри
              <b-icon :icon="!showFilter ? 'caret-down' : 'caret-up'" font-scale="1" />
            </b-button>
            <b-button variant="outline-primary" @click="create">
              Додати показник
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <b-row class="mt-3" v-show="showFilter">
        <FilterByConstants ref="filters" :filters="filters" @init="(filters) => apiGetItems(filters)" />
      </b-row>
    </b-container>

    <b-container fluid class="mt-5">
      <b-table hover :items="items" :fields="fields" ref="table" head-variant="primary" show-empty stacked="lg">
        <template #table-colgroup="scope">
          <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.key === 'unit' ? '400px' : '' }" />
        </template>

        <template #cell(id)="row">
          {{ ++row.index }}
        </template>

        <template #cell(constants)="row">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form ref="form" @submit.stop.prevent="handleSubmit(submit)">
              <b-row cols="1" cols-md="2" cols-lg="3">
                <b-col v-for="type in constantType.filter(
                  (el) => el.type === row.item.unit_type
                )" :key="type.id">
                  <validation-provider :name="'Показник ' + type.label" rules="required|double:2,dot|max:15"
                    v-slot="validationContext">
                    <b-input-group class="mb-3">
                      <template #prepend>
                        <b-input-group-text v-html="type.label"></b-input-group-text>
                      </template>
                      <b-form-input :disabled="row.item.disabled" trim v-model="row.item.constants[type.id].value"
                        required :state="GlobalGetValidationState(validationContext)"></b-form-input>
                      <b-form-invalid-feedback v-html="validationContext.errors[0]" />
                    </b-input-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-button v-if="!row.item.disabled" class="ml-auto" variant="outline-primary"
                @click="handleSubmit(update(row.item))">Зберегти</b-button>
            </form>
          </validation-observer>
        </template>

        <template #cell(actions)="row">
          <b-button v-b-tooltip class="mt-1 mr-1" :variant="row.item.disabled ? 'outline-primary' : 'primary'" size="sm"
            title="Редагувати" @click="row.item.disabled = !row.item.disabled">
            <b-icon icon="pencil" font-scale="1" />
          </b-button>
          <!--          <b-button-->
          <!--            v-b-tooltip class="mt-1 mr-1"-->
          <!--            variant="outline-danger"-->
          <!--            size="sm"-->
          <!--            title="Видалити"-->
          <!--            v-b-modal.modal-destroy-->
          <!--            @click="() => { item = row.item; dialogDestroy = false }"-->
          <!--          >-->
          <!--            <b-icon icon="trash" font-scale="1"/>-->
          <!--          </b-button>-->
        </template>

        <template #empty="scope">
          <h6 class="text-center my-2">Немає записів для показу</h6>
        </template>
      </b-table>
    </b-container>

    <m-create-constant :open="showModalCreate" :fields="fieldsCreate" :title="item ? 'Редагувати' : 'Створити'"
      @submit="store" :student="item" ref="mCreate" @reset="resetCreate" />
  </div>
</template>

<script>
import FilterByConstants from "@/components/Filters/ByConstants";
import api from "@/api";
import { ALLOWED_REQUEST_PARAMETERS, API } from "@/api/constants-api";
import MCreateConstant from "@/components/modal/createConstant";
import Toasts from "@/mixins/Toasts";

export default {
  name: "ConstantsPage",
  components: { MCreateConstant, FilterByConstants },
  mixins: [Toasts],
  data() {
    return {
      students: [],
      current_page: 1,
      filters: [],
      fields: [
        { key: "id", label: "№" },
        { key: "unit", label: "Факультет/Інститут/Кафедра" },
        { key: "constants", label: "Показники" },
        { key: "actions", label: "Дії" },
      ],
      unitList: [],
      items: [],
      showFilter: false,
      item: null,
      dialogDestroy: false,
      fieldsCreate: [],
      showModalCreate: false,
      constantType: [],
    };
  },
  mounted() {
    this.apiGetItems();
    this.apiGetFilters();
    this.apiGetIndicatorTypes();
  },
  methods: {
    apiGetFilters() {
      api
        .get(API.INDICATORS_FILTERS)
        .then(({ data }) => {
          this.filters = data;
        })
        .catch((errors) => {
          console.log("PROGRESS_MARKS_FILTERS", errors);
        });
    },
    apiGetItems(payload) {
      const options = this.GlobalHandlingRequestParameters(
        ALLOWED_REQUEST_PARAMETERS.GET_INDICATORS,
        payload
      );

      api
        .get(API.INDICATORS, options, { showLoader: true })
        .then(({ data }) => {
          this.items = data.data;
        })
        .catch((errors) => {
          this.ToastError(errors);
        });
    },
    apiGetCreate() {
      api
        .get(API.INDICATORS_CREATE, null, { showLoader: true })
        .then(({ data }) => {
          this.fieldsCreate = data;
        })
        .catch((errors) => {
          this.ToastError(errors);
        });
    },
    apiGetIndicatorTypes() {
      api
        .get(API.CONSTANT_TYPES, null, { showLoader: true })
        .then(({ data }) => {
          this.constantType = data.data;
        })
        .catch((errors) => {
          this.ToastError(errors);
        });
    },
    update(item) {
      api
        .patch(API.INDICATORS, 1, item.constants)
        .then(({ data }) => {
          this.ToastSuccess(data.message);
          item.disabled = true;
        })
        .catch((errors) => {
          this.ToastError(errors.response.data.message);
          console.log("API.INDICATORS update errors", errors);
        });
      console.log("update", item);
    },
    store(item) {
      api
        .post(API.INDICATORS, item)
        .then(({ data }) => {
          this.ToastSuccess(data.message);
          this.showModalCreate = false;
          this.resetCreate();
          this.apiGetItems(this.$refs.filters.getFilterData());
          this.apiGetFilters();
        })
        .catch((er) => {
          this.ToastError(er.response.data.message);
        });
    },
    create() {
      this.apiGetCreate();
      this.showModalCreate = true;
    },
    resetCreate() {
      this.$refs.mCreate.items = [];
      this.$refs.mCreate.unit = null;
      this.showModalCreate = false;
      this.item = null;
    },
  },
};
</script>

<style scoped></style>
