<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">

    <form ref="form" @submit.stop.prevent="handleSubmit(submit)">
      <b-modal v-model="dialog" id="modal-create-supervisor" centered :title="title + ' керіника'"
        header-bg-variant="primary" header-text-variant="light" cancel-title="Закрити" ok-title="Зберегти" size="xl"
        @ok.prevent="handleSubmit(submit)" @hidden="$emit('reset')">
        <validation-provider name="Науковий керівник" rules="required" v-slot="validationContext">
          <b-form-group id="supervisor-group" label="Науковий керівник" label-for="supervisor-input">
            <CSelect :loading="loadingSupervisors" :options="supervisors" id="supervisor-input" label="full_name"
              v-model="item.full_name" :invalid="validationContext.invalid && validationContext.validated"
              :valid="validationContext.valid && validationContext.validated" @search="getSearchSupervisor"
              @input="(val) => selectSupervisor = val" :reduce="(option) => option" :disabled="!!item.id" />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-row>
          <b-col col lg="12">
            <validation-provider name="Посада" rules="required" v-slot="validationContext">
              <b-form-group id="position-group" label="Посада" label-for="position-input">
                <b-form-input id="position-group" v-model="item.position" type="text" placeholder="Керівник"
                  :state="GlobalGetValidationState(validationContext)"></b-form-input>
              </b-form-group>
            </validation-provider>

          </b-col>
        </b-row>
        <validation-provider name="Факультет/Інститут" rules="required" v-slot="validationContext">
          <b-form-group id="unit-group" label="Факультет/Інститут" label-for="unit-input">
            <CSelect :options="fields.units" id="unit-input" label="department" v-model="item.unit_id"
              :invalid="validationContext.invalid && validationContext.validated"
              :valid="validationContext.valid && validationContext.validated" :reduce="(option) => option.id" />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider name="Кафедра" rules="required" v-slot="validationContext">
          <b-form-group id="department-group" label="Кафедра" label-for="department-input">
            <CSelect :options="departments" id="department-input" label="department" v-model="item.department_id"
              :invalid="validationContext.invalid && validationContext.validated"
              :valid="validationContext.valid && validationContext.validated" :reduce="(option) => option.id" />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>


      </b-modal>
    </form>
  </validation-observer>
</template>

<script>
import CSelect from "@/components/Forms/CSelect";
import api from "@/api";
import debounce from 'lodash.debounce';
import { API, ALLOWED_REQUEST_PARAMETERS } from "@/api/constants-api";

export default {
  name: "mCreateSupervisor",
  components: { CSelect },
  data() {
    return {
      dialog: this.open,
      defaultItem: {
        asu_id: null,
        position: null,
        unit_id: null,
        department_id: null,
      },
      item: {},
      supervisors: [],
      search: '',
      loadingSupervisors: false,
      selectSupervisor: null,
      departments: [],
    }
  },
  props: {
    open: {
      default: false
    },
    title: '',
    student: null,
    fields: {
      required: true,
    }
  },
  watch: {
    open(v) {
      this.dialog = v
    },
    student(v) {
      if (v && v !== null) {
        this.item = v;
        this.apiGetStudents({ full_name: this.item.full_name });
      }
    },
    'item.unit_id'(v, old) {
      if (old && v !== old) {
        this.item.department_id = null;
      }
      if (v !== null) {
        this.apiGetDepartments(v);
      } else {
        this.departments = [];
      }
    }
  },
  mounted() {
    this.apiGetStudents();
  },
  methods: {
    apiGetDepartments(id) {
      api.get(API.GET_DEPARTMENTS_LIST + '/' + id).then(({ data }) => {
        this.departments = data
      }).catch(er => {
        console.log(er)
        this.ToastError(er.response.data.message);
      })
    },
    apiGetStudents(payload) {
      const options = this.GlobalHandlingRequestParameters(ALLOWED_REQUEST_PARAMETERS.GET_ASU_SEARCH_PERSON, payload);

      api.get(API.ASU_ALL_SUPERVISORS_SEARCH, options).then(({ data }) => {
        this.loadingSupervisors = false;
        this.supervisors = data;
      }).catch((errors) => {
        this.loadingSupervisors = false;
        console.log(errors)
      });
    },
    submit() {
      const department = this.departments.find(el => el.id === this.item.department_id)?.department;
      const unit = this.fields.units.find(el => el.id === this.item.unit_id)?.department;

      const data = { ...this.item, ...this.selectSupervisor, department, unit }

      this.$emit('submit', data);
    },
    resetForm() {
      this.item = { ...this.defaultItem };
      this.selectSupervisor = null;
      this.supervisors = [];
      this.departments = [];
    },
    getSearchSupervisor: debounce(async function (searchStr) {
      if (searchStr.length > 3) {
        this.loadingSupervisors = true;
        await this.apiGetStudents({ full_name: searchStr });
      }
    }, process.env.VUE_APP_DEBOUNCE_DELAY),
  }
}
</script>

<style scoped></style>
